import pGift from "../../../assets/images/privilege-gift.svg";
import pSport from "../../../assets/images/privilege-s-sport.svg";
import pStar from "../../../assets/images/privilege-star.svg";
import pTicket from "../../../assets/images/privilege-ticket.svg";
import "./Privileges.scss";

interface IProps {}

const Privileges = (props: IProps) => {
  return (
    <div id="privileges">
      <div className="box-wrapper no-select">
        <span className="info">
          TARAFTAR KART
          <br />
          <strong>AVANTAJLARIN</strong>
        </span>
        <div className="box">
          <img className="icon" src={pTicket} alt="ticket" />
          <span className="text">
            Öncelikli
            <br />
            maç bileti
          </span>
        </div>
        <div className="box">
          <img className="icon" src={pSport} alt="discount" />
          <span className="text">
            S Sport Plus
            <br />
            İndirimi
          </span>
        </div>
        <div className="box">
          <img className="icon" src={pGift} alt="gift" />
          <span className="text">
            Hediye
            <br />
            4.000 FreeByte
          </span>
        </div>
        <div className="box">
          <img className="icon" src={pStar} alt="star" />
        </div>
      </div>
    </div>
  );
};

export default Privileges;
