import parse from "html-react-parser";
import React from "react";
import giftBanner from "../../assets/images/gift-banner.svg";
import { GiftDto } from "../../core/models/dtos/gift.dto";
import FirebaseService from "../../core/services/firebase.service";
import "./GiftPage.scss";

class GiftPage extends React.Component {
  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_view_gift);
  }

  getRewardInstructions(): GiftDto {
    return JSON.parse(
      FirebaseService.getValue(FirebaseService.reward_instructions)
    );
  }

  render() {
    return (
      <div id="gift-page" className="page">
        <div className="page-content">
          <div className="section section-gift col-12">
            <div className="heading">
              <img src={giftBanner} alt="gift" />
              <div className="info">
                <span className="text">4.000 FREEBYTE</span>
              </div>
            </div>
          </div>

          {/*<img className="placeholder" src={giftPlaceholder} alt="placeholder"/>*/}

          <div className="section section-gift-description">
            <div className="heading">
              <div className="info">
                <span className="title">
                  {parse(this.getRewardInstructions().rewardTitle)}
                </span>
                <span className="text">
                  {parse(this.getRewardInstructions().rewardDescription)}
                </span>
              </div>
            </div>
            <div className="body"></div>
          </div>

          <div className="section section-gift-option ">
            <div className="heading">
              <div className="info">
                <span className="title">
                  {parse(this.getRewardInstructions().rewardOption1_title)}
                </span>
                <span className="text">
                  {parse(this.getRewardInstructions().rewardOption1_text)}
                </span>
                <span className="title">
                  {parse(this.getRewardInstructions().rewardOption2_title)}
                </span>
                <span className="text">
                  {parse(this.getRewardInstructions().rewardOption2_text1)}
                </span>
              </div>
            </div>
            <div className="body"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default GiftPage;
